<template>
        <main>
            <router-view />
            <FooterMain />
            <div class="up" @click="Scroll">Вверх</div>
        </main>
</template>
<script>
import FooterMain from "@/components/Footer/FooterNoCity";
export default {
    name: "LayoutNoCity",
    metaInfo() {
        return {
            titleTemplate: (titleChunk) => {
                return titleChunk ? (titleChunk === "404" ? `${titleChunk}` : `${titleChunk} ${this.$myCity.name} - Окей Город`) : "Окей Город";
            },
            meta: [{ rel: "icon", href: "/favicon.ico" }],
        };
    },
    components: {
        FooterMain
    },
    data() {
        return {};
    },
    methods: {
        Scroll() {
            this.$scrollToTop();
        },
        onScroll() {
            if (window.scrollY > 300) {
                document.querySelector('.up').style.display = 'flex';
            } else if (window.scrollY <= 300) {
                document.querySelector('.up').style.display = 'none';
            }
        },

    },
    created() {
        window.addEventListener('scroll', this.onScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.onScroll);

    },
    mounted() {},
    watch: {},
};
</script>
<style>
#app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background: #edeef0;
    min-height: 100vh;
}

main {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
}

.up {
    display: none;
    position: fixed;
    border-radius: 100%;
    height: 60px;
    width: 60px;
    justify-content: center;
    align-items: center;
    background: #4a76a8;
    color: #fff;
    outline: none;
    cursor: pointer;
    z-index: 1000 !important;
    bottom: 15px;
    right: 15px;
}

@media only screen and (max-width: 767px) {
    .up {
        height: auto;
        width: auto;
        border-radius: 6px 6px 0 0;
        padding: 5px 35px;
        font-size: 14px;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
    }

}
</style>
