<template>
    <footer itemscope itemtype="http://schema.org/WPFooter">
        <nav itemtype="http://schema.org/SiteNavigationElement">
            <div class="footer_section bg_white">
                <div class="global_container">
                    <div class="footer_links_box">
                        <router-link :to="{ name: 'cityList' }" itemprop="url" class="item">
                            <span>Выбор города</span>
                        </router-link>
                        <router-link :to="{ name: 'Partnership' }" itemprop="url" class="item" target="_blank">
                            <span>Франшиза "Окей Город"</span>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="footer_section">
                <div class="global_container" style="background: #eee; padding: 15px 0">
                    <div class="footer_download_box">
                        <div class="qr_container">
                            <div class="qr_box">
                                <div class="qr_item">
                                    <img src="/images/qr.png" alt="" class="img-fluid" />
                                </div>
                                <div class="qr_description">
                                    <div class="qr_title">Установка мобильного приложения</div>
                                    <div class="qr_desc">
                                        Наведите камеру своего телефона на QR-код и перейдите<br />
                                        по ссылке для установки приложения "Окей Город"
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="downloads_box">
                            <div class="download_app_button">
                                <a href="https://play.google.com/store/apps/details?id=com.goroda.cities" target="_blank" rel="nofollow" @click="reachYA('download_googlePlay')">
                                    <img src="/images/icon/GooglePlay.svg" alt="GooglePlay" title="GooglePlay" class="icon" />
                                </a>
                                <a href="https://apps.apple.com/ru/app/id1361334339" target="_blank" rel="nofollow" @click="reachYA('download_ios')">
                                    <img src="/images/icon/AppStore.svg" alt="AppStore" title="AppStore" class="icon" />
                                </a>
                                <a href="https://appgallery.huawei.com/#/app/C103438881" target="_blank" rel="nofollow" @click="reachYA('download_appgallery')">
                                    <img src="/images/icon/huawei-appgallery.svg" alt="AppGallery" title="AppGallery" class="icon" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer_section bg_white">
                <div class="global_container">
                    <div class="copyrights_box">
                        <router-link :to="{ name: 'cityList' }" itemprop="url" class="logo_box" title="Главная">
                            <img src="/images/logo_100.png" class="img-fluid" />
                        </router-link>
                        <span>© Все права защищены / All rights reserved</span>
                        <span>Разработано компанией <strong itemprop="copyrightHolder" v-html="`Окей Город`"></strong></span>
                        <span itemprop="copyrightYear" v-html="`2017 - ${$moment().format('YYYY г.')}`" class="leftMenu_footer_date"></span>
                    </div>
                </div>
            </div>
        </nav>
    </footer>
</template>
<script>
export default {
    name: "FooterMain",
    components: {},
    data() {
        return {};
    },
    methods: {},
};
</script>
<style scoped>
footer {
    margin-top: auto;
    padding-top: 50px;
}
.footer_section {
    padding: 15px;
}

.footer_links_box {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
}

.footer_links_box a {
    font-size: 13px;
    color: #666666;
    transition: .2s;
}

.footer_links_box a:hover {
    transition: .2s;
    cursor: pointer;
    color: #4a76a8;
}

.footer_links_box a span {
    display: flex;
    text-align: left;
}
.footer_download_box {
    display: flex;
    justify-content: space-between;
}

.download_app_button {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}

.download_app_button img {
    height: 50px;
}

.logo_box {
    margin-bottom: 30px;
    cursor: pointer;
}
.copyrights_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #666666;
}

.qr_box {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.qr_item {
}
.qr_item img {
    max-height: 150px;
}

.qr_description {
    font-family: "Ubuntu";
    padding: 20px 0 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
}

.qr_title {
    font-weight: bold;
    text-align: left;
    color: #3b3e44;
    font-size: 20px;
}

.qr_desc {
    padding-top: 20px;
    font-size: 14px;
    text-align: left;
    color: #666666;
}

@media only screen and (max-width: 767px) {
    footer .footer_section:last-child {
        padding-bottom: 50px;
    }

    .downloads_box {
        margin: 0 auto;
    }
    .download_app_button img {
        height: 50px;
    }

    .qr_container {
        display: none;
    }
}

@media (min-width: 576px) and (max-width: 992px) {
    .qr_container {
        display: none;
    }

    .downloads_box {
        margin: 0 auto;
    }
}

@media only screen and (max-width: 576px) {
    .download_app_button {
        justify-content: center;
    }

    .download_app_button img {
        height: 70px;
    }
}
</style>
